import { ChequebookStatus } from './../../enums/chequebookStatus';
import { ScheckheftService } from './../../service/scheckheft/scheckheft-service';
import { YearViewModel, ScheckheftDetailViewModel, ActivityViewModel } from '../../view-models/scheckheft-detail-view-model';
import { autoinject } from 'aurelia-framework';
import { ConfigProvider, Logger } from "voltospa";
import { ProfileService } from '../../service/profile/profile-service';
import { TenantContext } from "../../service/tenant/tenant-context";
import { TenantService } from "../../service/tenant/tenant-service";
import { UILayoutService } from "../../service/ui/ui-layout-service";
import { UiNavigationService } from "../../service/ui/ui-navigation-service";
import { PageBase } from "../contents/Base/page-base";
import { AuthService } from '../../service/auth/auth-service';
import { EnvironmentConfigurationService } from '../../service/configuration/environment-configuration-service';

@autoinject
export class Detail extends PageBase {

    private ChequebookStatus=ChequebookStatus;
    public displayName: string;
    public isDataValid: boolean = true;
    public years: YearViewModel[] = [];
    public currentYear: number = 2024; // Fix auf 2024 gesetzt, da es das letzte gültige Jahr ist
    public yearFrom: number = 2024 - 2; // Fix auf 2024 gesetzt, da es das letzte gültige Jahr ist & nur -2 statt -3 da 2021 nicht mehr einreichbar
    public selectedYear: YearViewModel;
    public chequeBookDetails: ScheckheftDetailViewModel = new ScheckheftDetailViewModel();
    public selectedCategory: string;
    

    private membershipId: number;

    get isSelectedPrintButtondisabled(): boolean {

        if (this.chequeBookDetails.activities) {
            var activitiesToPrint = this.chequeBookDetails.activities.filter(a => a.selectedForPrint);
            return activitiesToPrint.length == 0;
        }

        return true;
    }

    constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, layout: UILayoutService, logger: Logger,
        public nav: UiNavigationService, private profileService: ProfileService, private scheckheftService: ScheckheftService, private auth: AuthService,
        private environmentConfig: EnvironmentConfigurationService ) {
        super(tenantService, config, tenantProvider, layout, logger, nav);

        if (!this.auth.IsAuthenticated()) {
            this.nav.navigate("start");
        }
    }

    async attached(){
        
    }
    async activate(params: any, route, navigationInstruction) {

        this.displayName = params.name;
        this.membershipId = params.membershipId;
        
        var userName = this.displayName.toLowerCase().replace(new RegExp(' ', 'g'), '');

        if (this.auth.IsAuthenticated()) {
            var userProfile = await this.profileService.getUserProfile(false);  
            userProfile.subInsurers =userProfile.subInsurers.filter(si => si.membership);   

            if (userProfile.subInsurers.length > 0) {
                var subInsurer = userProfile.subInsurers.filter(si => si.membership.membershipId == this.membershipId)[0];

                if (subInsurer) {
                    var subInsurerName = (subInsurer.firstname + subInsurer.lastname).toLowerCase().replace(new RegExp(' ', 'g'), '');
                    this.isDataValid = userName == subInsurerName; 
                    this.setYearFrom(subInsurer.membership);

                    if (subInsurer.namePrefix) {
                        this.displayName = subInsurer.firstname + ' ' + subInsurer.namePrefix + ' ' + subInsurer.lastname;
                    }
                }
                else {
                    this.isDataValid = false;
                }
            }
            else {               
                this.setYearFrom(userProfile.mainInsurer.membership);
            }

            if (!this.isDataValid) {
                this.isDataValid = userProfile.mainInsurer.membership.membershipId == this.membershipId;

                if (this.isDataValid) {
                    var insurerName = (userProfile.mainInsurer.firstname + userProfile.mainInsurer.lastname).toLowerCase().replace(new RegExp(' ', 'g'), '');
                    this.isDataValid = userName == insurerName;
                    this.setYearFrom(userProfile.mainInsurer.membership);
                    
                    if (userProfile.mainInsurer.namePrefix) {
                        this.displayName = userProfile.mainInsurer.firstname + ' ' + userProfile.mainInsurer.namePrefix + ' ' + userProfile.mainInsurer.lastname;
                    }
                }
            }
        }
        else {
            this.isDataValid = false;
        }

        if (this.isDataValid) {
            await this.initilize();
        }
    }

    private async initilize() {
        try {
            await this.initilizeStaticData();
            await this.changeSelectedYear(this.years[0]);    
        } catch (error) {
            this.isDataValid=false;
        }
        
    }

    private async initilizeStaticData() {

        var showNextYear =(await this.environmentConfig.getConfiguration()).shownextyear;
        if(showNextYear){
            var nextYear =  this.currentYear+1;
            var year = new YearViewModel();
            year.year = nextYear;
            year.displayString = nextYear.toString();
            year.id = 'jahr' + nextYear;

            this.years.push(year);
        }

        for (var i = this.currentYear; i >= this.yearFrom; i--) {
            var year = new YearViewModel();
            year.year = i;
            year.displayString = i.toString();
            year.id = 'jahr' + i;

            this.years.push(year);
        }
    }

    private setYearFrom( membership){
        this.yearFrom = membership.startDate.getFullYear() > this.yearFrom ? membership.startDate.getFullYear() : this.yearFrom;
    }

    private checkPreConditionsForYearChange(): boolean {
        if (this.chequeBookDetails.activities) {
            if (this.chequeBookDetails.activities.filter(a => a.selectedForPrint).length > 0) {
                return confirm("Sie haben bereits Maßnahmen ausgewählt, durch den Wechsel des Jahres wird die Auswahl rückgängig gemacht. Sind Sie sich sicher, dass sie das Jahr wechseln wollen?")
            } else {
                return true;
            }
        }
        else {
            return true;
        }
    }

    async changeSelectedYear(newSelectedYear: YearViewModel) {
        if (this.checkPreConditionsForYearChange()) {
            $('.collapse.show').collapse('hide');
            this.selectedYear = newSelectedYear;
           
                this.chequeBookDetails = await this.scheckheftService.getActivitiesByYear(this.membershipId, this.selectedYear.year);
                this.chequeBookDetails.selectAllForPrint = false;    
           
           
           
            
            await this.changeSelectedCategory(this.chequeBookDetails.categories[0]);
        }
        else {
            $('#' + newSelectedYear.id + '-tab').removeClass('active');
            $('#' + this.selectedYear.id + '-tab').addClass('active');
        }
    }

    async changeSelectedCategory(category: string) {
        this.selectedCategory = category;
    }

    async toggleCheckbookSelection(selectAll: boolean) {
        for (var i = 0; i < this.chequeBookDetails.activities.length; i++) {
            this.chequeBookDetails.activities[i].selectedForPrint = selectAll && !this.isActivityDisabled(this.chequeBookDetails.activities[i]);
        }
    }

    isActivityDisabled(activity: ActivityViewModel): boolean {
        if (activity && activity.status != null) {
            var result = (activity.status.toLowerCase() != 'Rejected'.toLowerCase());
            return result
        } else {
            return false;
        }
    }


    async printActivity(activity: ActivityViewModel) {
        if (!this.isActivityDisabled(activity)) {
            var number = [activity.id];
            await this.getPdfChequebook(number);
        }
    }

    async submitRedemption(activity: ActivityViewModel) {
        if (!this.isActivityDisabled(activity)) {
            this.nav.navigate('scheckheft-einreichen', { membershipId: this.membershipId, year: this.selectedYear.year, activity: activity.id });
        }
    }

    async printSelectedActivities() {
        var activitiesToPrint = this.chequeBookDetails.activities.filter(a => a.selectedForPrint);
        var number = [];

        activitiesToPrint.forEach(async activity => {
            number.push(activity.id);
        });

        if (number.length > 0) {
            await this.getPdfChequebook(number);
        }
    }
    async getPdfChequebook(activityIds: number[]) {
        var requestSuccess = await this.scheckheftService.getChequebookPdfFromBackend(this.membershipId, activityIds);
        if (requestSuccess == false) {
            alert("Bei der Erstellung des Scheckhefts ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.");
        }
    }
}