import { BootstrapErrorRenderer } from './../contents/CustomElements/BootstrapErrorRenderer';
import { ProfileInformation, ProfileViewModel } from './../../view-models/profile-view-model';
import { autoinject, observable, bindable,bindingMode } from 'aurelia-framework';
import { ConfigProvider, Logger, MenuItem, NotificationService } from "voltospa";
import { AuthService } from "../../service/auth/auth-service";
import { ProfileService } from "../../service/profile/profile-service";
import { TenantContext } from "../../service/tenant/tenant-context";
import { TenantService } from "../../service/tenant/tenant-service";
import { UILayoutService } from "../../service/ui/ui-layout-service";
import { UiNavigationService } from "../../service/ui/ui-navigation-service";
import { PageBase } from "../contents/Base/page-base";
import { ActivityViewModel, ScheckheftDetailViewModel } from '../../view-models/scheckheft-detail-view-model';
import { ScheckheftService } from '../../service/scheckheft/scheckheft-service';
import { ValidationController, ValidationRules, ValidationControllerFactory } from 'aurelia-validation';

import {activationStrategy, RoutableComponentDetermineActivationStrategy} from 'aurelia-router';
import { EnvironmentConfigurationService } from '../../service/configuration/environment-configuration-service';

@autoinject()
export class Einreichen extends PageBase {

    private shouldInitilize: boolean = false;
    private isDataValid: boolean = true;
    private userProfile: ProfileViewModel;
    private availableUsers: ProfileInformation[] = [];
    private availableYears: number[] = [];
    private yearFrom: number = 2024 - 2; // Fix auf 2024 gesetzt, da es das letzte gültige Jahr ist & nur -2 statt -3 da 2021 nicht mehr einreichbar
    private selectedDate: Date;    
    private dateTextValue: string;
    private allowedFiletypes: string[] = ["image/png", "image/jpeg", "application/pdf"];
    private maxFileSize: number = 2 * 1024 * 1024;
    private maxNumberOfFiles = 2;
    private fileErrors: string[] = [];
    private validationController: ValidationController;
    private initializationSuccessfull : boolean=false;
    public chequeBookDetails: ScheckheftDetailViewModel = new ScheckheftDetailViewModel();
    public availableActivities: ActivityViewModel[] = [];
    public requestSucess: boolean = false;
    public activityRequested: boolean = false;

    @bindable public selectedUser: ProfileInformation = null;
    @bindable public selectedUserLongName: string = "";
    @bindable ({defaultBindingMode: bindingMode.twoWay}) public selectedYear: number;
    @bindable public selectedActivity: ActivityViewModel = null;
    @observable public filesList: File[] = [];

    constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, layout: UILayoutService, logger: Logger,
        public nav: UiNavigationService, private profileService: ProfileService, private auth: AuthService, private scheckheftService: ScheckheftService,
        private validationControllerFactory: ValidationControllerFactory, private notificationService: NotificationService, 
        private environmentConfig: EnvironmentConfigurationService ) {
        super(tenantService, config, tenantProvider, layout, logger, nav);

        if (!this.auth.IsAuthenticated()) {
            this.nav.navigate("start");
        }

        this.validationController = this.validationControllerFactory.createForCurrentScope();
        this.validationController.addRenderer(new BootstrapErrorRenderer());

        layout.disableMobileActionBar();

        this.setValidationRules();
    }

   
    async activate(params: any, route, navigationInstruction) {

        if (this.auth.IsAuthenticated()) {
            this.userProfile = await this.profileService.getUserProfile(false);

            if (this.userProfile.subInsurers.filter(si => si.membership).length == 0) {
                this.userProfile.subInsurers = [];
            }

            this.isDataValid = true;
        }
        else {
            this.isDataValid = false;
        }

        if (this.isDataValid) {
            try {
                await this.initilizeData(params.membershipId, params.year, params.activity);
                this.selectedUser = this.availableUsers[0];
                if(this.selectedUser.firstname != undefined)
                    this.selectedUserLongName = this.selectedUser.firstname + " ";
                if(this.selectedUser.namePrefix != undefined)
                    this.selectedUserLongName += this.selectedUser.namePrefix +  " ";
                if(this.selectedUser.lastname != undefined)
                    this.selectedUserLongName += this.selectedUser.lastname;
                this.shouldInitilize = true;    
                this.initializationSuccessfull=true;
            } catch (error) {
                this.initializationSuccessfull=false;
            }
            
        }
      
    }

    async attached() {
        let dropArea = document.getElementById('file-drop-area');

        if (!dropArea) return;

        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            dropArea.addEventListener(eventName, this.preventDefaults, false)
            document.body.addEventListener(eventName, this.preventDefaults, false)
        });

        ['dragenter', 'dragover'].forEach(eventName => {
            dropArea.addEventListener(eventName, this.highlight, false)
        });

        ['dragleave', 'drop'].forEach(eventName => {
            dropArea.addEventListener(eventName, this.unhighlight, false)
        });

        dropArea.addEventListener('drop', (event) => { this.handleDrop(event); }, false);


    }

    highlight(event) {
        let dropArea = document.getElementById('file-drop-area');
        if (dropArea.classList.contains('highlight')) {
            return;
        }
        dropArea.classList.add('highlight');
    }

    unhighlight(event) {
        let dropArea = document.getElementById('file-drop-area');
        dropArea.classList.remove('highlight');
    }

    preventDefaults(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    handleDrop(fileDropEvent) {
        let hasInavlidFiles = false;
        let hasLargeFiles = false;

        if (fileDropEvent.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            for (var i = 0; i < fileDropEvent.dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (fileDropEvent.dataTransfer.items[i].kind === 'file') {
                    var file = fileDropEvent.dataTransfer.items[i].getAsFile();
                    if (file.size <= this.maxFileSize) {
                        if (this.allowedFiletypes.indexOf(file.type) > -1) {
                            if (this.filesList.length < this.maxNumberOfFiles) {
                                this.filesList.push(file);
                            }
                        } else {
                            hasInavlidFiles = true;
                        }
                    } else {
                        hasLargeFiles = true;
                    }
                }
            }

            if (fileDropEvent.dataTransfer.items.length > this.maxNumberOfFiles) {
                alert('Sie haben zu viele Dateien ausgewählt. Es wurden nur die ersten gültigen ' + this.maxNumberOfFiles + ' Dateien hinzugefügt.');
            }

        } else {
            // Use DataTransfer interface to access the file(s)
            for (var i = 0; i < fileDropEvent.dataTransfer.files.length; i++) {
                if (fileDropEvent.dataTransfer.files[i].size <= this.maxFileSize) {
                    if (this.allowedFiletypes.indexOf(fileDropEvent.dataTransfer.files[i].type) > -1) {
                        if (this.filesList.length < this.maxNumberOfFiles) {
                            this.filesList.push(fileDropEvent.dataTransfer.files[i]);
                        }
                    } else {
                        hasInavlidFiles = true;
                    }
                } else {
                    hasLargeFiles = true;
                }
            }

            if (fileDropEvent.dataTransfer.files.length > this.maxNumberOfFiles) {
                alert('Sie haben zu viele Dateien ausgewählt. Es wurden nur die ersten gültigen ' + this.maxNumberOfFiles + ' Dateien hinzugefügt.');
            }
        }

        if (hasLargeFiles) {
            alert('Sie haben einige zu große Dateien ausgewählt. Es werden nur die  Dateien mit der erlaubten Größe (kleiner ' + (this.maxFileSize / (1024 * 1024)) + ' MB) hinzugefügt.');
        }

        if (hasInavlidFiles) {
            alert('Sie haben nicht unterstützte Dateien ausgewählt. Es werden nur die unterstützten Dateien hinzugefügt.');
        }
    }

    fileSelected(fileSelectedEvent) {
        let hasInavlidFiles = false;
        let hasLargeFiles = false;

        for (var i = 0; i < fileSelectedEvent.target.files.length; i++) {
            if (fileSelectedEvent.target.files[i].size <= this.maxFileSize) {
                if (this.allowedFiletypes.indexOf(fileSelectedEvent.target.files[i].type) > -1) {
                    if (this.filesList.length < this.maxNumberOfFiles) {
                        this.filesList.push(fileSelectedEvent.target.files[i]);
                    }
                } else {
                    hasInavlidFiles = true;
                }
            } else {
                hasLargeFiles = true;
            }
        }

        if (fileSelectedEvent.target.files.length > this.maxNumberOfFiles) {
            alert('Sie haben zu viele Dateien ausgewählt. Es wurden nur die ersten gültigen ' + this.maxNumberOfFiles + ' Dateien hinzugefügt.');
        }

        if (hasLargeFiles) {
            alert('Sie haben einige zu große Dateien ausgewählt. Es werden nur die  Dateien mit der erlaubten Größe (kleiner ' + (this.maxFileSize / (1024 * 1024)) + ' MB) hinzugefügt.');
        }

        if (hasInavlidFiles) {
            alert('Sie haben nicht unterstützte Dateien ausgewählt. Es werden nur die unterstützten Dateien hinzugefügt.');
        }
    }

    deleteFile(index: number) {
        this.filesList.splice(index, 1);
    }

    async selectedUserChanged() {
        if (this.shouldInitilize) {
            await this.initilizeData(this.selectedUser.membership.membershipId);
        }
    }

    async selectedYearChanged() {
        if (this.shouldInitilize) {
            await this.initilizeData(this.selectedUser.membership.membershipId, this.selectedYear);
        }
    }

    async reInitilizeData(lastState: boolean) {
        if(lastState){
            await this.nav.navigate('scheckheft-details', { membershipId: this.selectedUser.membership.membershipId, name: this.selectedUser.firstname + " " + this.selectedUser.lastname });
          }
        else{
            this.activityRequested=false;
            //this.nav.navigate('start');
        }
    }

    public cancel() {
        if (this.checkPreConditonBeforeNavigation()) {
            this.nav.navigate('scheckheft');
        }
    }

    public async submitData() {
        this.notificationService.clear();
        
        var validationResult = await this.validationController.validate();
        if (this.validateFields() && validationResult.valid) {
            try {
                this.requestSucess = await this.scheckheftService.redeemChequebookActivity(this.selectedUser.membership.membershipId, this.selectedActivity.id, this.selectedDate, this.filesList);
            } catch (error) {
                this.requestSucess = false;
            }

            this.activityRequested = true;
        }
    }

    private validateFields(): boolean {
        this.fileErrors = [];

        if (this.filesList.length == 0) {
            this.fileErrors.push('Bitte wählen Sie eine Datei zur Bestätigung aus.');
            return false;
        } else {
            return true;
        }
    }

    private checkPreConditonBeforeNavigation(): boolean {

        if (this.filesList.length > 0) {
            return confirm('Sie haben bereits Dateien ausgewählt, durch Abbrechen wird die Auswahl rückgängig gemacht. Sind Sie sich sicher, dass Sie die Seite verlassen wollen ?');
        } else {
            return true;
        }
    }

    private async initilizeData(membershipId?: number, year?: number, activity?: number) {
        if (membershipId) {
            if (this.userProfile.subInsurers.length > 0) {
                var subInsurer = this.userProfile.subInsurers.filter(si => si.membership != undefined && si.membership.membershipId == membershipId)[0];
                if (subInsurer) {
                    if (!this.shouldInitilize) {
                        this.availableUsers.push(subInsurer);
                    }
                    this.yearFrom = subInsurer.membership.startDate.getFullYear() >= this.yearFrom ? subInsurer.membership.startDate.getFullYear() : this.yearFrom;
                    this.isDataValid = true;
                }
                else {
                    this.isDataValid = false;
                }
            }
            if (!this.isDataValid) {
                this.isDataValid = this.userProfile.mainInsurer.membership.membershipId == membershipId;
            }

            if (this.isDataValid && this.availableUsers.length == 0) {
                this.yearFrom = this.userProfile.mainInsurer.membership.startDate.getFullYear() >= this.yearFrom ? this.userProfile.mainInsurer.membership.startDate.getFullYear() : this.yearFrom;
                if (!this.shouldInitilize) {
                    this.availableUsers.push(this.userProfile.mainInsurer);
                }
            }
        }
        if (!this.shouldInitilize && this.availableUsers.length == 0) {
            this.availableUsers.push(this.userProfile.mainInsurer);

            if (this.userProfile.subInsurers.length > 0) {
                this.userProfile.subInsurers.forEach(element => {
                    this.availableUsers.push(element);
                });
            }

            this.isDataValid = true;
        }

        this.selectedActivity = null;
        this.availableYears = [];

        if (membershipId && year && this.yearFrom <= year && !this.shouldInitilize) {
            this.selectedYear = year;
        }
        var showNextYear =(await this.environmentConfig.getConfiguration()).shownextyear;
        if(showNextYear)
        {
            this.availableYears.push(new Date().getFullYear()+1);
        }

        // Fix auf 2024 gesetzt, da es das letzte gültige Jahr ist
        for (var i = 2024; i >= this.yearFrom; i--) {
            this.availableYears.push(i);
        }
        this.chequeBookDetails = await this.scheckheftService.getActivitiesByYear(this.availableUsers[0].membership.membershipId, this.selectedYear ? this.selectedYear : this.availableYears[0]);
        var allActivitiesWithoutState = this.chequeBookDetails.activities.filter(act => act.status == null);
        var allActivitiesWithState = this.chequeBookDetails.activities.filter(act => act.status != null);

        var unwantedStates=["locked", "accepted", "modified", "open"];

        var validActivities = allActivitiesWithState.filter(activity => unwantedStates.indexOf(activity.status.toLowerCase()) === -1);

        this.availableActivities = validActivities.concat(allActivitiesWithoutState);

        this.availableActivities.sort(function(a,b){
            var nameA = a.name.toUpperCase();
            var nameB = b.name.toUpperCase();
            if(nameA < nameB){
                return -1
            }
            if(nameA > nameB){
                return 1
            }
            return 0
        })
        
        if (membershipId && year && activity) {
            this.selectedActivity = this.availableActivities.find(a => a.id == activity);
        }
        else{
            this.selectedActivity = this.availableActivities[0];
        }
    }

    activityMatcher = (a: ActivityViewModel, b: ActivityViewModel) => {
        let result: boolean = false;
        if (a && b) {
            result = a.id === b.id;
        }
        return result;
    };

    private setValidationRules() {

        ValidationRules
            .ensure((item: this) => item.dateTextValue).required()
                .withMessage("Bitte geben Sie das Datum der Maßnahme an.").minLength(1)
                .withMessage("Bitte geben Sie das Datum der Maßnahme an.")
            .ensure((item: this) => item.selectedDate).required().when((item: this)=> item.dateTextValue && item.dateTextValue.length>0)
                .withMessage("Das von Ihnen eingegebene Maßnahmedatum stimmt nicht mit dem Scheckjahr überein. Bitte überprüfen Sie Ihre Angaben.</br>Sie wollten die Maßnahme für ein anderes Jahr einreichen? Dann klicken Sie <a class='feedback' onclick='window.history.back();return false;'>hier</a>")
            .on(this);
    }

}